.form-group {
  display: flex;
  flex-direction: column;

}
/* .absolute {
  position: absolute;
} */
#submit {
  position: relative;
  bottom: -12px;
  background-color: #C6D8FF;
}
form {
  display: flex;
  flex-direction: column;
}
#contact {
  padding: 10px 0;
  text-align:center;
}
.orderedList {
  width: fit-content;

}
input, textarea {
  background-color: #121212;
  border: none;
  padding: 8px 10px;
  border-radius: 10px;
}
.list>ol {
  width: 50%;
  margin: auto;
  list-style: none;
  display: flex;
  padding: 10px 5px;
  justify-content: center;
  
}
.icon {
  margin: 0 5px;
}