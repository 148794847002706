#fishLips {
  width: 75%;
  height: 75%;
}
img {
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  box-shadow: 1px 3px 2px #121212;
}
.img {
  display: flex;
  padding: 10px 0px;
}
.popup {
  width: auto;
}
.interest-list {
  width:80%;
  text-align: left;
  margin: auto;
}
.interest-list>ul {
  padding: 0;
  /* margin: auto; */
  width:fit-content;
  list-style-position: inside;
}
ul>li {
  text-align: left;
}

.temp {
  width: 66%;
  margin: auto;
  background-color: #333333;
  border-radius: 10px;
  box-shadow: 1px 3px 2px #121212;
  padding: 10px 10px;
  /* -webkit-animation: slideInFromRight 1s ease-in 0s 1;
  animation: slideInFromRight 1s ease-in 0s 1; */
}
.center {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}
.emphasize {
  color: #C6D8FF;
}
/* 69DC9E */
ul.center {
  margin: auto;
}