.projectImg {
  width: 75%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.project-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  border-radius: 10px;
  text-align: center;
  /* margin-top: 15px;
  margin-bottom: 15px; */
  width: 85%;
  height: 100%;

}
.container {
  height: auto;
  width: 100%;
  position: relative;
}

.repo-container {
  display: flex;
  justify-content: space-evenly;
}
.button-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
}
.slider {
  position: relative;
  top: -30px;
  padding: 20px 23px;
}
.animate-on-change {
  height: 75%;
}
.another-container {
  width: 100%;
  position: relative;
}
@media all and (min-width: 850px) {
  .button-container {
    width: 95%;
    height: fit-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  }
}
@media all and (min-width: 1050px) {
  .button-container {
    width: 90%;
  }
}