body {
  margin: 0;
  padding: 0;
  width: 100%;
  height:100%;
  color: rgba(255, 255, 255, 60%);
  background-color: #121212;
  font-family:'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color:  rgba(255, 255, 255, 60%);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
