header {
  width: 100%;
  height: fit-content;
  padding-bottom: 10px;
  box-shadow: 1px 3px 2px #121212;
}
h2 {
  text-align: center;
}
.opening {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
#nav-ul {
  padding-left: 0;
  display: flex;
  list-style-type: none;
  justify-content: space-evenly;
  
}
#nav-ul>li>a {
  color: #FE7F2D;
}
.span-p {
  
  display: inline-block;
  position: relative;
  /* -webkit-animation: fadeIn 1.5s ease-in 0s 1;
  animation: fadeIn 1.5s ease-in 0s 1; */
  /* color: rgba(255,255,255, 75%); */
}
.span-p>p {
  margin: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
    /* transform: translateX(-300%); */
  }
  100% {
    opacity: 100%;
    /* transform: translateX(0); */
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 25%;
    transform: translateX(300%);
  }
  100% {
    opacity: 100%;
    transform: translateX(0);
  }
}
@media all and (min-width: 500px) {
  h1 {
    font-size: 45px;
  }
  body {
    font-size: 18px;
  }
  h2 {
    font-size: 35px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 25px;
  }
  .span-p {
    font-size: 20px;
  }
}