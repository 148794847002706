main {
  padding-top: 20px;
}
.big-container {
  width: 90%;
  height: 100%;
  background-color: #1C1C1C;
  border-radius: 10px;
  margin:auto;
  display: flex;
  flex-direction: column;
  position: relative;
  
}
.container {
  width: auto;
  height: 100%;
}
.animate-on-change {
  height:auto;
  width: auto;
}
.indent {
  text-indent: 20px;
}

@media all and (min-width: 900px) {
  .opening {
    flex-direction: row;
    width: 75%;
    margin:auto;
    justify-content: flex-start;
    align-items: baseline;
  }
  h1 {
    padding-right: 25px;
  }
  .list {
    position: absolute;
    top: -150px;
    right: -15px;
    height: max-content;
  }
  .list>ol {
    flex-direction: column;
  }
}